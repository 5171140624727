import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import EventEmitter from 'eventemitter3';
import { useDispatch, useSelector } from 'react-redux';

import URI from 'urijs';
import Plausible from 'plausible-tracker';

import './App.css';

import Timer, { formatTime } from './Timer';
import Grid from './Grid';
import Definitions from './Definitions';
import Success, { onShare } from './Success';
import Pause from './Pause';
import Splash from './Splash';
import Logo from './Logo';
import Modal from './Modal';
import Share from './Share.js';
import Subscribe from './Subscribe.js';
import ControlBar from './ControlBar';

import Keyboard from "react-simple-keyboard";
import { setDefinitions, setAnswer, setGrid, setSeenSplash, setSelected, setHints, setHintsKey, setAutoVerify, setAvgTime, setSelectedDirection, addReveal, incrTotalGridsPlayed, incrTotalHintsUsed, incrTotalGridsCompleted, incrTotalElapsedTime, setStatsPersisted, setBattleCode, setMode, pushHistory, setOpponentHistory, setBattleUsername } from './store';
import { setPause, reset } from './store';

import { BASE_API } from './config';
import Loader from './Loader';

import { useDarkMode, useMediaQuery } from 'usehooks-ts'
import { useOrientation } from "@uidotdev/usehooks";
import dayjs from 'dayjs';
import { getFirstCellOfWordByNumber } from './utils/grid';
import { getCurrentDay } from './utils/getCurrentDay';
import { decrypt } from './utils/crypt';
import { moveToNextCell, moveToPreviousCell } from './utils/move';
import { postMessage } from './utils/messages';
import Help from './Pages/Help';
import Privacy from './Pages/Privacy';
import About from './Pages/About';

import fallbackData from './fallbackData.js';
import Stats from './Pages/Stats.js';
import MoreGrids from './MoreGrids.js';
import { capitalizeFirstLetter } from './utils/letters.js';

import Close from "./images/close.svg"
import CloseDark from "./images/close-dark.svg"

import { usePostHog } from 'posthog-js/react';
import Whoops from './Whoops.js';
import Leaderboard from './Pages/Leaderboard.js';

require('dayjs/locale/fr');
dayjs.locale('fr');

const emitter = new EventEmitter();

// this hook fake the number of live players
// live players must be the same for each player when he arrives on page
// it must depend on the current hour and respond to a Gauss curve distribution between 9am and 9pm
// then, randomly, every 10 seconds, the number of live players is incremented or decremented by 1 or 2 max
const LivePlayersCount = () => {
  const now = dayjs();
  const start = dayjs().startOf('day').add(9, 'hours');
  const diff = now.diff(start, 'hours');
  const max = 20;
  const initialPlayers = Math.round(max * Math.exp(-Math.pow(diff - 6, 2) / (2 * Math.pow(3, 2))));

  const [livePlayers, setLivePlayers] = useState(initialPlayers);

  // use a setTimeout to simulate a live number of players
  useEffect(() => {
    setTimeout(() => {
      const increments = [-2, -1, 1, 2];
      const newLivePlayers = livePlayers + increments[Math.floor(Math.random() * increments.length)];

      setLivePlayers(Math.max(1, newLivePlayers));
    }, 10000);
  }, [livePlayers]);

  return livePlayers;
}

function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

let fetchedOnce = false;

const App = () => {
  const dispatch = useDispatch();

  const pause = useSelector(state => state.app.pause);
  const success = useSelector(state => state.app.success);
  const seenSplash = useSelector(state => state.app.seenSplash);
  const elapsedTime = useSelector(state => state.app.elapsedTime);

  const answer = useSelector(state => state.app.answer);
  const hints = useSelector(state => state.app.hints);
  const hintsKey = useSelector(state => state.app.hintsKey);
  const reveals = useSelector(state => state.app.reveals);
  const autoVerify = useSelector(state => state.app.autoVerify);
  const selected = useSelector(state => state.app.selected);
  const selectedDirection = useSelector(state => state.app.selectedDirection);
  const grid = useSelector(state => state.app.grid);
  const statsPersisted = useSelector(state => state.app.statsPersisted);

  const totalGridsPlayed = useSelector(state => state.stats.totalGridsPlayed);
  const totalGridsCompleted = useSelector(state => state.stats.totalGridsCompleted);
  const currentStreak = useSelector(state => state.stats.currentStreak);
  const longestStreak = useSelector(state => state.stats.longestStreak);

  const [hasShared, setHasShared] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [hideSuccess, setHideSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [layoutName, setLayoutName] = useState("default");
  const [hintsUsed, setHintsUsed] = useState(0);
  const [referred, setReferred] = useState(false);
  const [competingTime, setCompetingTime] = useState(null);
  const [viewPanel, setViewPanel] = useState(false);
  const [landscapeWarning, setLandscapeWarning] = useState(false);

  const boardRef = useRef(null);
  const [boardHeight, setBoardHeight] = useState(0);
  const [boardWidth, setBoardWidth] = useState(0);
  const [isTooOld, setIsTooOld] = useState(false);

  const battleCode = useSelector(state => state.app.battleCode);
  const username = useSelector(state => state.app.username);
  const battleUsername = useSelector(state => state.app.battleUsername);

  const url = new URL(window.location.href);
  window.url = url;

  let {
    preview,
    embed,
    mode,
    battle_code,
    leaderboard,
  } = (new URI(window.location.href)).search(true);

  let date = getCurrentDay(emitter, isTooOld);

  const isEmbedded = typeof embed !== 'undefined';
  const fragment = window.location.hash;

  if (preview === 'tomorrow')
    date = dayjs().add(1, 'day').format('YYYY-MM-DD');

  if (!date)
    date = dayjs().format('YYYY-MM-DD');

  const initGame = (data) => {
    setError(null);

    // do not reset the game if we are in battle mode
    if (mode !== `battle`)
      dispatch(reset());

    dispatch(setAnswer(data.answer));
    dispatch(setDefinitions(data.definitions));

    let size = 5;

    try {
      size = data.hints[0].length;
    } catch (e) {}

    // initialize black cells on grid
    if (data.blackCells) {
      const grid = Array(size).fill(null).map(() => Array(size).fill(''));

      data.blackCells.forEach(([x, y]) => grid[x][y] = '#');

      dispatch(setGrid(grid));
      dispatch(setSelected(getFirstCellOfWordByNumber(1, grid)));
    }

    if (data.hints) {
      dispatch(setHints(data.hints));
      dispatch(setHintsKey(data.hintsKey ||  dayjs().format('YYYY-MM-DD')));
    }

    if (data.avgTime)
      dispatch(setAvgTime(data.avgTime));

    if (mode === `battle`)
      dispatch(setMode('battle'));

    if (data.battle_code) {
      dispatch(setBattleCode(data.battle_code));
      dispatch(setOpponentHistory(data.history));
    }

    if (data.username)
      dispatch(setBattleUsername(data.username));

    dispatch(incrTotalGridsPlayed());

    postMessage({ type: 'gridLoaded' });
  }

  const fetchData = async (date) => {
    if (fetchedOnce) return;

    fetchedOnce = true;

    try {
      const url = new URI(BASE_API)
        .segment(mode === `battle` ? `battle` : `grid`);

      if (date)
        url.addSearch('date', date);

      if (battle_code)
        url.addSearch('battle_code', battle_code);

      if (username)
        url.addSearch('username', username);

      const response = await fetch(url.toString());

      if (!response.ok)
        throw new Error('Network response was not ok');

      const data = await response.json();

      // if battle, init game score and history
      if (data.battle_code) {
        await fetch(`${BASE_API}/battle/init?battle_code=${data.battle_code}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        });
      }

      // do nothing if we already have the grid in the store
      if (data.answer === answer)
        return;

      initGame(data);
    } catch (error) {
      console.log('Error fetching data', error);
      setError("Impossible de charger les définitions du jour. Veuillez réessayer plus tard. Merci.");
    }
  };

  const onReveal = () => {
    const hint = decrypt(hintsKey, hints[selected.x][selected.y]);

    emitter.emit('keyPress', { value: hint, hint: true });
    emitter.emit('onReveal');

    dispatch(addReveal(selected));
    dispatch(incrTotalHintsUsed());

    if (mode === `battle`) {
      const move = {
        x: selected.x,
        y: selected.y,
        elapsedTime: elapsedTime,
        isValid: true,
        hint: true,
      };

      dispatch(pushHistory(move));
    }

    trackEvent(
      'hint',
      {
        type: 'reveal',
      }
    )

    setHintsUsed(hintsUsed + 1);
  }

  const onAutoVerify = () => {
    dispatch(setAutoVerify(!autoVerify))
    trackEvent(
      'hint',
      {
        type: 'auto-verify',
        date: dayjs(date ? date : dayjs().format()).format('YYYY-MM-DD'),
      }
    )

    setHintsUsed(hintsUsed + 1);
    dispatch(incrTotalHintsUsed());
  }

  // if ?reset=true is present in the URL, reset the game
  useEffect(() => {
    if (url.searchParams.get("reset")) {
      dispatch(reset());
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (mode !== 'battle')
      fetchData(date).finally(() => setLoading(false));
    else
      setLoading(false);
  }, []);

  // if &share is present in the URL, store referred to state and remove the params t, c and share
  useEffect(() => {
    if (url.searchParams.get("t"))
      setCompetingTime(url.searchParams.get("t"));

    const removeQueryArgs = () => {
      if (url.searchParams.get("share")) {

        setReferred(true);

        trackEvent(
          'referred',
          {
            date: dayjs(date ? date : dayjs().format()).format('YYYY-MM-DD'),
            referred: true
          }
        )

        url.searchParams.delete('t');
        url.searchParams.delete('c');
        url.searchParams.delete('share');
        window.history.replaceState({}, document.title, url);
      }

      // same for utm_ params
      if (url.search.includes('utm_')) {
        url.searchParams.delete('utm_source');
        url.searchParams.delete('utm_medium');
        url.searchParams.delete('utm_campaign');
        window.history.replaceState({}, document.title, url);
      }
    }

    // put a timeout to ensure Plausible has time to track the params
    setTimeout(removeQueryArgs, 1000);
  }, []);

  useEffect(() => {
    const onKeyPress = (e) => {
      // do nothing if splash screen is still open
      if (!seenSplash)
        return;

      // support capital letters
      if (e.keyCode >= 65 && e.keyCode <= 90) {
        emitter.emit('keyPress', e.key.toLowerCase());
        return;
      }

      if (e.key === "Backspace") {
        emitter.emit('keyPress', '{backspace}');
        return;
      }

      // detect only arrow keys
      if (e.key !== "ArrowUp" && e.key !== "ArrowDown" && e.key !== "ArrowLeft" && e.key !== "ArrowRight")
        return;

      if (success || pause)
        return;

      if ((e.key === "ArrowLeft" || e.key === "ArrowRight") && selectedDirection === "down") {
        dispatch(setSelectedDirection("across"));
        return;
      }

      if ((e.key === "ArrowUp" || e.key === "ArrowDown") && selectedDirection === "across") {
        dispatch(setSelectedDirection("down"));
        return;
      }

      // move to previous cell
      if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
        const [nextSelected, nextSelectedDirection] = moveToPreviousCell(selected, selectedDirection, grid);
        dispatch(setSelected(nextSelected));
        dispatch(setSelectedDirection(nextSelectedDirection));
      }

      // move to next cell
      if (e.key === "ArrowDown" || e.key === "ArrowRight") {
        const [nextSelected, nextSelectedDirection] = moveToNextCell(selected, selectedDirection, grid);
        dispatch(setSelected(nextSelected));
        dispatch(setSelectedDirection(nextSelectedDirection));
      }
    }

    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    }
  }, [selected, selectedDirection]);

  // migrate stats that were stored differently
  useEffect(() => {
    if (!success || statsPersisted)
      return;

    // persist stats
    dispatch(incrTotalGridsPlayed());
    dispatch(incrTotalGridsCompleted());
    dispatch(incrTotalElapsedTime(elapsedTime));
    dispatch(incrTotalHintsUsed(reveals.length));
    dispatch(setStatsPersisted(true));

    console.log('migrated stats');

  }, [success, statsPersisted]);

  // Tracking with Plausible via plausible-tracker
  const plausible = Plausible({
    domain: 'playmotif.fr',
    trackLocalhost: false,
  })
  const posthog = usePostHog();

  // Track pageview on first load
  useEffect(() => {
    plausible.trackPageview();
    trackEvent(
      'load',
      {
        date: dayjs().format('YYYY-MM-DD'),
      },
    )
    postMessage({ type: 'pageLoaded' });
  }, []);

  const trackEvent = (name, props, cb) => {
    plausible.trackEvent(
      name,
      {
        props,
        callback: () => cb && cb()
      },
      { trackLocalhost: true }
    );
    posthog.capture(
      name,
      props
    );
  }

  // media query to detect mobile
  const isMobile = useMediaQuery('(max-width: 768px)', {
    defaultValue: true, // important to set defaultValue to true because of MoreGrids
  })
  const isMobileLarge = useMediaQuery('(max-width: 1024px)', {
    defaultValue: false,
  })
  const isTablet = useMediaQuery('(max-width: 1280px)', {
    defaultValue: true, // important to set defaultValue to true because of MoreGrids
  })

  // detect touch devices
  const isTouch = isTouchDevice();

  const handleWindowResize = () => {
    if (!boardRef.current) return;

    setBoardHeight(boardRef.current.offsetHeight);
    setBoardWidth(boardRef.current.offsetWidth);
  }

  // detect orientation landscape to display a warning and to lock orientation on mobile
  const orientation = useOrientation();

  useEffect(() => {
    if (orientation.type.startsWith('landscape') && !isEmbedded && isMobileLarge)
      setLandscapeWarning(true);
    else
      setLandscapeWarning(false);
  }, [orientation.type, isMobileLarge, isEmbedded]);

  // useRef to get height of content
  useLayoutEffect(() => {
    if (!boardRef.current) return;

    handleWindowResize();
  }, [boardRef.current]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    emitter.on('gridLoaded', handleWindowResize);
    emitter.on('too_old', setIsTooOld);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      emitter.off('gridLoaded', handleWindowResize);
      emitter.off('too_old', setIsTooOld);
    };
  }, [isTooOld]);

  useEffect(() => {
    if (success && !isTablet && !isEmbedded) {
      setViewPanel(true);
    }
  }, [success, isTablet, isEmbedded]);

  if (mode !== `battle` && posthog.getFeatureFlag('splash_screen') === 'no_splash') {
    if (!landscapeWarning) {
      dispatch(setSeenSplash(true));
    }
  }

  const boardRatio = boardWidth / boardHeight;

  const {isDarkMode} = useDarkMode();
  const CloseIcon = isDarkMode ? CloseDark : Close;

  if (loading) {
    const LoadingText = [
      "Chargement de Motif en cours...",
      "Allez, un Motif, un ! Chargement en cours...",
      "Et un Motif pour la 4 ! Chargement en cours...",
      "Un Motif, ça vous dit ? Chargement en cours...",
    ]

    return (
      <div className="App App--Loading">
        <Loader text={LoadingText[Math.floor(Math.random() * LoadingText.length)]} />
      </div>
    );
  }

  if (error) {
    return (
      <Modal
        isOpened={true}
        showClose={false}
        footer={
          <div
          onClick={() => {
            initGame(fallbackData);
          }}
          className="ActionBtn Button--primary"
        >Charger la grille de débug</div>
        }
      >
        <h1>Oups...</h1>
        <p>{error}</p>
      </Modal>
    );
  }

  if (isTooOld) {
    return (
      <Modal
        isOpened={true}
        footer={
          <>
            <div
              style={{ marginBottom: '1rem' }}
              onClick={() => {
                window.location.href = 'https://apps.apple.com/fr/app/motif-les-mots-crois%C3%A9s/id6479329303';
              }}
              className="ActionBtn">La retrouver sur l'application iPhone
            </div>
            <div
              onClick={() => {
                // redirect to /
                window.location.href = window.location.origin;
              }}
              className="ActionBtn Button--primary">Revenir à la grille du jour
            </div>
          </>
        }
      >
        <h1>Oups...</h1>
        <p>La grille du {capitalizeFirstLetter(dayjs(isTooOld).format('dddd D MMMM'))} est trop ancienne pour être affichée sur le web.</p>
      </Modal>
    );
  }

  if (leaderboard)
    return <Leaderboard battleCode={battle_code} />

  if (fragment === '#help')
    return <Help />

  if (fragment === '#privacy')
    return <Privacy />

  if (fragment === '#about')
    return <About />

  if (fragment === '#stats')
    return <Stats />

  return (
    <div className={`App ${viewPanel ? 'has-Panel' : ''}`}>

      <div className="Main">

        {!isEmbedded &&
          <>

            <Splash
              mode={mode}
              battleCode={battle_code}
              isOpened={!seenSplash}
              competingTime={competingTime}
              onDownload={() => {
                trackEvent('download_app');
              }}
              onClose={async () => {

                if (mode === 'battle') {
                  await fetchData(date);
                  dispatch(setSeenSplash(true));
                  trackEvent('start_battle');
                  return;
                }

                dispatch(setSeenSplash(true));
                trackEvent(
                  'close_splash',
                  {
                    date: dayjs(date ? date : dayjs().format()).format('YYYY-MM-DD'),
                    referred: referred,
                    currentStreak,
                    longestStreak,
                    totalGridsPlayed,
                    totalGridsCompleted,
                  }
                );
              }}
            />

            <Modal
              isOpened={landscapeWarning}
              showClose={false}
            >
              <h1>Salut 👋</h1>
              <p>Tourne ton appareil en mode portrait pour jouer à Motif sur ton téléphone.</p>
            </Modal>

          </>
        }

        <Whoops
          emitter={emitter}
          autoVerify={autoVerify}
          onAutoVerify={onAutoVerify}
        />

        <Pause
          isOpened={pause && !success}
          onClose={() => dispatch(setPause(false))}
        />

        <Success
          mode={mode}
          answerBattle={!!battle_code}
          isOpened={success && !hideSuccess}
          isEmbedded={isEmbedded}
          date={date}
          onClose={() => setHideSuccess(true)}
          onDownload={() => {
            trackEvent('download_app');
          }}
          onMoreGrids={() => {
            setViewPanel(true)
            postMessage({ type: 'moreGrids' })
          }}
          onHasShared={() => {
            setHasShared(true);
            setHideSuccess(true);
            setShowRegister(true);
            trackEvent(
              'share',
              {
                date: dayjs(date ? date : dayjs().format()).format('YYYY-MM-DD'),
                time: elapsedTime,
                referred: referred
              }
            );
          }}
          competingTime={competingTime}
          onShowRegister={() => setShowRegister(true)}
        />

        <Share
          isOpened={showRegister}
          onClose={() => setShowRegister(false)}
          onSubscribeClicked={() => {
            setShowRegister(false);
            setShowSubscribe(true);
            trackEvent(
              'subscribe',
              {
                date: dayjs(date ? date : dayjs().format()).format('YYYY-MM-DD'),
              }
            );
          }}
          hasShared={hasShared}
        />

        <Subscribe
          isOpened={showSubscribe}
          onClose={() => setShowSubscribe(false)}
        />

        {!isEmbedded && (
          <div className="Header">
            <div onClick={() => window.location.href = '/'} className="Logo-Wrapper">
              {isTablet ? <Logo type="logomark" /> : <Logo />}
            </div>
            <div className="Motif-Infos">
              {mode === `battle` ? (
                <p>Bataille {battleUsername ? `de ${battleUsername}` : ''}</p>
              ) : (
                <p>Motif du {dayjs(date ? date : dayjs().format()).format(isMobile ? 'D MMM' : 'dddd D MMM')}</p>
              )}
            </div>
            <div className="Header-Aside">
              <div className="LiveCounter-Wrapper" title="Joueurs en ligne">
                <div className="LiveCounter-Dot"></div>
                <span className="LiveCounter-Counter"><LivePlayersCount /></span>
                {isTablet ? "" : <span className="LiveCounter-Suffix">joueurs en ligne</span>}
              </div>
            </div>
          </div>
        )}

        <div className={`Content ${success ? "is-success" : "not-success"}`}>

          <div className="BoardWrapper">

            <div className={`Board ${isMobile ? "BoardVertical" : boardRatio > 1 ? "BoardHorizontal" : "BoardVertical"}`} ref={boardRef}>

              <Grid
                mode={mode}
                emitter={emitter}
                boardSize={{ width: boardWidth, height: boardHeight }}
                boardRatio={boardRatio}
                isMobile={isMobile}
                onSuccess={() => {
                  trackEvent('success',
                  {
                    date: dayjs().format('YYYY-MM-DD'),
                    elapsedTime,
                    hintsUsed,
                    currentStreak,
                    longestStreak,
                    totalGridsPlayed,
                    totalGridsCompleted,
                  })
                  postMessage({ type: 'success', elapsedTime });
                }}
              />

              {(boardRatio > 1 && !isMobile) && (
                <Definitions isMobile={isMobile} position="right" />
              )}

            </div>

            {(boardRatio < 1 || isMobile) && (
              <Definitions isMobile={isMobile} position="bottom" />
            )}

            <ControlBar
              className={isEmbedded ? 'is-embedded' : 'not-embedded'}
              children={
                <>
                  {(!success && hints?.length > 0) && (
                    <div className="ControlBar-Actions">
                      {!isMobile && (
                        <div className="Label">Coups de pouce</div>
                      )}
                      <div className={`ActionBtn Button Button--small ${autoVerify ? 'Button--active' : ''}`} onClick={onAutoVerify}>{isMobile ? "Vérif" : "Vérification auto"}</div>
                      <div className="ActionBtn Button Button--small" onClick={onReveal}>{isMobile ? "Révéler" : "Révéler la case"}</div>

                    </div>
                  )}

                  {(seenSplash || isEmbedded) && !pause && !success && (
                    <div className="Timer-Wrapper">
                      <Timer emitter={emitter} isEmbedded={isEmbedded} />
                      {competingTime && (
                        <div className="Timer-CompetingTime">
                          <div className="Timer-CompetingTime-Separator">/</div>
                          <div>{formatTime(competingTime)}</div>
                        </div>
                      )}
                    </div>
                  )}

                  {(!success && (pause || (!seenSplash && !isEmbedded))) && (
                    <div className="Timer-Wrapper">
                      <div className="Timer">En pause</div>
                    </div>
                  )}

                  {(success) && (
                    <>
                      <div className="ControlBar-Actions">
                        <div className="ActionBtn Button Button--small" onClick={() => {
                          onShare(elapsedTime, mode, battleCode)
                          setShowRegister(true);
                          setHasShared(true);
                          trackEvent(
                            'share',
                            {
                              date: dayjs(date ? date : dayjs().format()).format('YYYY-MM-DD'),
                              time: elapsedTime,
                              referred: referred
                            }
                          );
                        }}>Partager</div>
                        {!isEmbedded && (
                          <div
                            className="ActionBtn Button Button--small"
                            onClick={() => {
                              setViewPanel(true)
                              postMessage({ type: 'moreGrids' })
                            }}
                          >Jouer plus</div>
                        )}
                      </div>
                      <div className="Timer-Wrapper">
                        <div className="Timer">
                          {isMobile ? "🏆   " + formatTime(elapsedTime) : `Victoire en ${formatTime(elapsedTime)}`}
                        </div>
                      </div>
                    </>
                  )}
                </>
              }
            />

            {isTouch && (
              <div className={`Keyboard ${isEmbedded ? 'is-embedded' : 'not-embedded'} ${success ? 'is-hidden' : 'is-shown'}`}>
                <Keyboard
                  layoutName={layoutName}
                  onKeyPress={(e) => {
                    if (e === "{shift}" || e === "{lock}") {
                      setLayoutName(layoutName === "default" ? "shift" : "default");
                      return;
                    }

                    emitter.emit('keyPress', e)
                  }}
                  physicalKeyboardHighlight={true}
                  physicalKeyboardHighlightPress={true}
                  layout={{
                    default: [
                      "a z e r t y u i o p",
                      "q s d f g h j k l m",
                      "w x c v b n {backspace}",
                    ],
                    shift: [
                      "A Z E R T Y U I O P",
                      "Q S D F G H J K L M",
                      "W X C V B N {backspace}",
                    ]
                  }}
                  display= {{
                    "{backspace}": "⌫",
                    "a": "A",
                    "z": "Z",
                    "e": "E",
                    "r": "R",
                    "t": "T",
                    "y": "Y",
                    "u": "U",
                    "i": "I",
                    "o": "O",
                    "p": "P",
                    "q": "Q",
                    "s": "S",
                    "d": "D",
                    "f": "F",
                    "g": "G",
                    "h": "H",
                    "j": "J",
                    "k": "K",
                    "l": "L",
                    "m": "M",
                    "w": "W",
                    "x": "X",
                    "c": "C",
                    "v": "V",
                    "b": "B",
                    "n": "N",
                  }}
                />
              </div>
            )}

          </div>

        </div>

      </div>

      {!isEmbedded && (
        <div className={`Panel ${viewPanel ? "Panel--in" : "Panel--out"}`}>
          <div className="Panel-Header">
            <div className="Panel-Title">Envie de plus de Motifs ?</div>
            <div className="CloseBtn" onClick={() => setViewPanel(false)}>
              <img src={CloseIcon} alt="Fermer" className="Icon" />
            </div>
          </div>
          <div className="Panel-Content">
            <MoreGrids />
          </div>
        </div>
      )}

    </div>
  );
}

export default App;
